<template>
  <div class="yt-main">
    <div class="yt-header">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/commodity/order/list' }">订单列表</el-breadcrumb-item>
        <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-content">
      <div class="information" :style="{ height: isPackUp1 ? '40px' : 'calc((100vh - 145px) / 4)' }">
        <div class="top">
          <p>基本信息</p>
          <div class="button" @click="isPackUp1 = !isPackUp1">
            <span v-text="isPackUp1 ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp1 ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <div class="info">
          <p class="detailed" v-for="(data, index) in basicInformation" :key="index">
            {{ data.label }}<span>{{ data.data }}</span>
          </p>
        </div>
      </div>
      <div class="information" :style="{ height: isPackUp2 ? '40px' : 'calc((100vh - 145px) / 4)' }">
        <div class="top">
          <p>付款人</p>
          <div class="button" @click="isPackUp2 = !isPackUp2">
            <span v-text="isPackUp2 ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp2 ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <div class="info">
          <img :src="buyer['buyerAvatar'].res" alt="" />
          <div class="info-right">
            <template v-for="(data, key, index) in buyer">
              <p class="detailedTwo" v-if="key !== 'buyerAvatar'" :key="index">
                {{ data.label }}<span>{{ data.data }}</span>
              </p>
            </template>
          </div>
        </div>
      </div>
      <div class="information" :style="{ height: isPackUp3 ? '40px' : 'calc((100vh - 145px) / 4)' }">
        <div class="top">
          <p>课程</p>
          <div class="button" @click="isPackUp3 = !isPackUp3">
            <span v-text="isPackUp3 ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp3 ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <div class="info">
          <img class="imgThree" :src="courseInfo['courseCover'].data" alt="" />
          <div class="info-rightT">
            <template v-for="(data, key, index) in courseInfo">
              <p class="detailedThree" v-if="key !== 'courseCover'" :key="index">
                {{ data.label }}
                <span v-if="key === 'courseName'"
                  ><a href="">{{ data.data }}</a></span
                >
              </p>
            </template>
          </div>
        </div>
      </div>
      <div class="information" :style="{ height: isPackUp4 ? '40px' : 'calc((100vh - 145px) / 4)' }">
        <div class="top">
          <p>日志</p>
          <div class="button" @click="isPackUp4 = !isPackUp4">
            <span v-text="isPackUp4 ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp4 ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import orderApi from '@api/order'

export default {
  name: 'OrderInfo',
  components: { YTIcon },
  data() {
    return {
      isPackUp1: false,
      courseId: null,
      isPackUp2: false,
      isPackUp3: false,
      isPackUp4: false,
      basicInformation: {
        orderNo: { label: '订单编号：', data: '' },
        // 1: { label: '订单类型：', data: '' },
        payAmount: { label: '订单金额：￥', data: '' },
        timeLimit: { label: '有效期：', data: '' },
        state: { label: '订单状态：', data: '' },
        createTime: { label: '订单时间：', data: '' }
      },
      buyer: {
        buyerAvatar: { data: require('../../../assets/monitor/courseCover.jpg') },
        buyerName: { label: '用户名：', data: '' },
        phone: { label: '电话：', data: '' },
        address: { label: '地址：', data: '' }
      },
      courseInfo: {
        courseCover: { data: '' },
        courseName: { label: '课程名称：', data: '' },
        totalTime: { label: '总课时：', data: '' }
      },
      log: []
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      orderApi.getStaffSearch(this.$route.query.orderId).then(res => {
        if (res.code === 0) {
          if (res.res.hasOwnProperty('buyer')) {
            Object.keys(this.buyer).forEach(key => {
              this.buyer[key].data = res.res.buyer[key]
            })
          }
          if (res.res.hasOwnProperty('courseInfo')) {
            Object.keys(this.courseInfo).forEach(key => {
              if (key === 'courseId') {
                this.courseId = res.res.courseInfo[key]
              }
              this.courseInfo[key].data = res.res.courseInfo[key]
            })
          }
          Object.keys(this.basicInformation).forEach(key => {
            if (key === 'createTime') {
              this.basicInformation[key].data = this.$formatTime(res.res[key], 'yyyy-MM-dd hh:mm')
            } else if (key === 'state') {
              this.basicInformation[key].data = this.getStatus(res.res[key])
            } else {
              this.basicInformation[key].data = res.res[key]
            }
          })
        }
      })
    },
    getStatus(type) {
      switch (type) {
        case 0:
          return '未支付'
        case 1:
          return '已完成'
        case 8:
          return '已失效'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';
.yt-main {
  padding-top: 0;
  .yt-header {
    height: 44px;
    align-items: center;
  }
}
.yt-content {
  height: calc(100vh - 104px);
  background: #f8f8f8;
  padding: 10px 20px;
  .information {
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    transition: height 1s ease-in-out;
    margin-bottom: 10px;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
    .top {
      .flexStyle(flex, space-between, center);
      padding: 10px 20px;
      height: 40px;
      border-bottom: 1px solid #e2e4e8;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
      }
      .button {
        .flexStyle(flex, center, center);
        width: 54px;
        height: 20px;
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        opacity: 1;
        border-radius: 13px;
        user-select: none;
        cursor: pointer;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #888888;
          margin-right: 4px;
        }
        svg {
          transition: all 1s ease-in-out;
          fill: #888888;
        }
      }
    }
    .info {
      padding: 20px 60px;
      height: calc(100% - 40px);
      width: 100%;
      .flexStyle(flex, flex-start, center);
      flex-wrap: wrap;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
      img {
        width: 44px;
        height: 44px;
        background: #f0f0f0;
        border-radius: 22px;
        margin-right: 20px;
      }
      span {
        color: #000000;
      }
      .imgThree {
        width: 120px;
        height: 90px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        opacity: 1;
        border-radius: 4px;
      }
      text-align: left;
      .detailed {
        width: calc(100% / 3);
      }
      .info-right {
        width: calc(100% - 64px);
        height: calc(100% - 40px);
        .flexStyle(flex, flex-start, center);
        flex-wrap: wrap;
        .detailedTwo {
          width: calc(100% / 2);
        }
      }
      .info-rightT {
        width: calc(100% - 140px);
        height: 100%;
        .flexStyle(flex, flex-start, flex-start);
        flex-wrap: wrap;
        .detailedThree {
          width: 100%;
        }
      }
    }
  }
}
</style>
